<template>
  <modal
    :name="modalName"
    adaptive
    width="90%"
    height="90%"
    :scrollable="true"
    class="compare--modal"
    @before-close="beforeClosed"
  >
    <div class="modal--wrapper">
      <section class="modal--container">
        <div class="d-flex justify-space-around align-center mt-3">
          <div class="first--col"></div>
          <div class="wrapper--content mb-3" v-for="listing in listings" :key="listing.key">
            <div class="wrapper--image">
              <div style="width: 100%; height: 100%" v-if="listing && listing.primary_photo">
                <picture>
                  <source :srcset="listing.primary_photo.url" type="image/webp" />
                  <source :srcset="getFallbackImage(listing.primary_photo.url)" type="image/jpeg" />
                  <img
                    :src="getFallbackImage(listing.primary_photo.url)"
                    class="img-responsive"
                    :alt="listing.listing_title"
                  />
                </picture>
              </div>
              <img
                v-else
                class="img-responsive"
                src="/img/fallback_image.jpg"
                :alt="listing.listing_title"
              />
            </div>
            <div class="listing--title text-center">{{ listing.listing_title }}</div>
          </div>
        </div>
        <div class="wrapper--info" v-if="listings">
          <!-- row for detail price -->
          <div class="info--row">
            <div class="first--col">
              {{ $t('general.compare.price') }}
            </div>
            <div class="wrapper--detail text-center" v-for="listing in listings" :key="listing.key">
              <div v-if="listing.type === 'R'">
                <div v-for="rentPrice in listing.rent_prices" :key="rentPrice.key">
                  {{ rentPrice.price_terbilang }}
                </div>
              </div>
              <div v-else>
                {{ $n(listing.sell_price, 'currency', 'id-ID') }}
              </div>
            </div>
          </div>
          <!-- row for detail installment -->
          <div class="info--row">
            <div class="first--col">
              {{ $t('general.compare.installment') }}
            </div>
            <div class="wrapper--detail text-center" v-for="listing in listings" :key="listing.key">
              <div v-if="listing.cicilan_gradana">
                <span>
                  Rp{{ listing.cicilan_gradana.sixMonthsInstallment }} /
                  {{ $t('fintechInstallment.formCicilan.6m') }}<br />
                  Rp{{ listing.cicilan_gradana.oneYearInstallment }} /
                  {{ $t('fintechInstallment.formCicilan.1year') }}<br />
                  Rp{{ listing.cicilan_gradana.twoYearInstallment }} /
                  {{ $t('fintechInstallment.formCicilan.2year') }}<br />
                </span>
              </div>
              <div v-else>
                {{ $t('fintechInstallment.formCicilan.notAvailable') }}
              </div>
            </div>
          </div>
          <!-- row for detail property type -->
          <div class="info--row">
            <div class="first--col">
              {{ $t('general.compare.propertyType') }}
            </div>
            <div class="wrapper--detail text-center" v-for="listing in listings" :key="listing.key">
              {{ listing.property.property_type_name }}
            </div>
          </div>
          <!-- row for detail address -->
          <div class="info--row">
            <div class="first--col">
              {{ $t('general.compare.address') }}
            </div>
            <div class="wrapper--detail text-center" v-for="listing in listings" :key="listing.key">
              {{ listing.property.address_line_1 }}&nbsp;
              {{ listing.property.address_line_2 }}
            </div>
          </div>
          <!-- row for detail address -->
          <div class="info--row">
            <div class="first--col">
              {{ $t('general.compare.rentType') }}
            </div>
            <div class="wrapper--detail text-center" v-for="listing in listings" :key="listing.key">
              {{ listing.rent_type }}
            </div>
          </div>
          <!-- row for detail size -->
          <div class="info--row">
            <div class="first--col">
              {{ $t('general.compare.buildingSize') }}
            </div>
            <div class="wrapper--detail text-center" v-for="listing in listings" :key="listing.key">
              {{ listing.size_in_square_meters }} m<sup>2</sup>
            </div>
          </div>
        </div>
      </section>
      <button type="button" class="close" @click="close">
        <img src="@/assets/img/icons/close-1.svg" />
      </button>
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';

export default {
  name: 'compareModal',
  mixins: [HelperMixin],
  computed: {
    ...mapState({
      listings: (state) => state.v2.listing.compareListings,
    }),
  },
  data: () => ({
    modalName: 'modal-compare',
  }),
  methods: {
    close() {
      this.$modal.hide(this.modalName);
    },
    beforeClosed() {
      this.$store.commit('v2/listing/reset_compare_listing');
      this.$store.commit('v2/listing/set_compare_show', false);
    },
  },
};
</script>
